import { useAPI, useNuxtApp, useCacheWrapper, toRef, useSharedPromise, useHydrationStore, useContextData } from '#imports'

import type { Shop, ShopDrivingDirection } from '@/modules/nuxt-api/models/Shop'
import type { ShopMetadata } from '@/modules/nuxt-api/models/Metadata'

type ModalShopOptions = {
  pickup: boolean
  button?: boolean
  zoom: number
  shops: Shop[]
  initShop: any
  type: 'list' | 'map'
  coordinates: number[]
}

type State = {
  shopListData: Shop[]
  currentShop: Shop | null
  shopMetadata: ShopMetadata | null | undefined
  shopInfoFSRAR: string[][] | null
  shopDirections: Record<string, ShopDrivingDirection[]>
}

export function useShopStore () {
  const api = useAPI()
  const nuxtApp = useNuxtApp()
  const state = useHydrationStore<State>('shop-store', {
    shopListData: [],
    currentShop: null,
    shopMetadata: null,
    shopInfoFSRAR: null,
    shopDirections: {}
  })

  const modalShopOptions = useContextData<ModalShopOptions>('modal-shop-options', {
    pickup: false,
    zoom: 11,
    shops: [],
    initShop: null,
    type: 'map',
    coordinates: []
  })

  const mapSettings = useHydrationStore<Pick<ModalShopOptions, 'zoom' | 'coordinates'>>('map-settings', {
    zoom: 11,
    coordinates: []
  })

  async function fetchShops (overrideRegion?: boolean) {
    const key = ['shops', overrideRegion ? '-region' : ''].filter(Boolean)
    const data = await useSharedPromise(key, () => useCacheWrapper(key, () => {
      const { getShops } = api.shop()
      return getShops(overrideRegion ?? false)
    }))

    if (data) {
      setData(data)
      state.value.shopListData = data
    }

    return state.value.shopListData
  }

  async function getShops (overrideRegion?: boolean) {
    if (state.value.shopListData.length === 0) {
      await nuxtApp.runWithContext(() => fetchShops(overrideRegion))
    }

    return state.value.shopListData
  }

  async function getShop (code: string) {
    const key = ['shop', code]
    if (state.value.currentShop && state.value.currentShop.code === code) { return state.value.currentShop }

    const data = await useSharedPromise(key, () => useCacheWrapper(key, () => {
      const { getShop } = api.shop()
      // [FIXME] Поправить параметры когда разберемся с запросом
      return getShop(code)
    }))

    if (data) {
      state.value.currentShop = data
    }

    return state.value.currentShop
  }

  async function getShopMetadata (code: string) {
    state.value.shopMetadata = await useCacheWrapper(['shop-metadata', code], () => {
      const { getShopMetadata } = api.shop()
      return getShopMetadata(code)
    })

    return state.value.shopMetadata
  }

  async function getInfoForFSRAR (shopCodeList: string[]) {
    if (state.value.shopInfoFSRAR) { return state.value.shopInfoFSRAR }

    const data = await nuxtApp.runWithContext(() => useSharedPromise('req',
      () => useCacheWrapper(['requisites', ...shopCodeList],
        () => {
          const { getInfoForFSRAR } = api.shop()
          return getInfoForFSRAR(shopCodeList)
        }
      )
    ))

    if (data) {
      state.value.shopInfoFSRAR = data
    }

    return state.value.shopInfoFSRAR
  }

  async function getShopDrivingDirections (code: string) {
    if (state.value.shopDirections[code]) { return state.value.shopDirections[code] }

    state.value.shopDirections[code] = await useCacheWrapper(['shop-directions', code], async () => {
      const { getShopDrivingDirections } = api.shop()
      const directions = await getShopDrivingDirections(code)
      return directions ? directions.filter(el => el.variantsList.length > 0) : []
    })

    return state.value.shopDirections[code]
  }

  function clearShopState () {
    state.value.currentShop = null
    state.value.shopMetadata = null
  }

  function setDefaultModalShopOptions () {
    modalShopOptions.value.button = true
    modalShopOptions.value.pickup = false
    modalShopOptions.value.initShop = null
    modalShopOptions.value.shops = []
  }

  function openShopModal (args?: Partial<ModalShopOptions>) {
    const { openModal } = useModals()
    settings()

    setDefaultModalShopOptions()

    if (args) {
      Object.assign(modalShopOptions.value, args)
    }

    openModal('shop-select')
  }

  function setPickUpShop (shop: Shop) {
    state.value.currentShop = shop
  }

  function setData (data: Shop[]) {
    settings()
    state.value.shopListData = data
  }

  function settings () {
    modalShopOptions.value.coordinates = mapSettings.value.coordinates
    modalShopOptions.value.zoom = mapSettings.value.zoom
  }

  return {
    getShops,
    getShop,
    getShopMetadata,
    getShopDrivingDirections,
    clearShopState,
    getInfoForFSRAR,
    setDefaultModalShopOptions,
    openShopModal,
    setPickUpShop,
    setData,
    settings,
    shopListData: toRef(state.value, 'shopListData'),
    currentShop: toRef(state.value, 'currentShop'),
    shopMetadata: toRef(state.value, 'shopMetadata'),
    modalShopOptions,
    mapSettings
  }
}
