import { defineAsyncComponent, defineComponent, createStaticVNode, h } from 'vue'

export default defineComponent({
  name: 'ServerRender',
  props: {
    is: {
      type: String,
      required: true
    }
  },
  setup (props, { slots }) {
    const instance = getCurrentInstance()

    if (import.meta.client) {
      const el = instance?.proxy?.$el

      if (el) {
        import(`@/components/nuxt/global/${props.is}/${props.is}.css`)
        return () => createStaticVNode(el.innerHTML ?? '', 1)
      }
    }

    const component = defineAsyncComponent(() => /* @vite-ignore */ import((`@/components/nuxt/global/${props.is}/index.vue`)))
    return () => h(component, () => slots?.default?.())
  }
})
