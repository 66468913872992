import { defineComponent, h } from 'vue'
import { useConfig } from '@/stores/config'

export default defineComponent({
  name: 'RegionOnly',
  props: {
    regionId: {
      type: Number
    },
    cityCode: {
      type: String
    }
  },
  setup (props, { slots }) {
    const { currentRegion } = useConfig()
    const canRender = currentRegion.value?.id === props.regionId || currentRegion.value?.city?.code === props.cityCode

    return () => h('div', canRender ? slots?.default!() : [])
  }
})
