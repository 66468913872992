const getFooterInterestingSections = async () => {
  return [
    { 'title': 'Бестселлеры', 'section': { 'path': { 'path': '/pages/bestsellers.html', 'value': '/pages/bestsellers.html' }, 'isClosed': false } },
    { 'title': 'Новинки', 'section': { 'path': { 'path': '/novelty/', 'value': '/novelty/' }, 'isClosed': false } },
    { 'title': 'Акции', 'section': { 'path': { 'path': '/promo/discount/', 'value': '/promo/discount/' }, 'isClosed': false } },
    { 'title': 'Вина с рейтингом 80+ до 1000 руб', 'section': { 'path': { 'path': '/wine/cheap-and-best/', 'value': '/wine/cheap-and-best/' }, 'isClosed': false } },
    { 'title': 'Вино в подарочной коробке', 'section': { 'path': { 'path': '/wine/inbox-packing/', 'value': '/wine/inbox-packing/' }, 'isClosed': false } },
    { 'title': 'Французский коньяк', 'section': { 'path': { 'path': '/cognac/france/', 'value': '/cognac/france/' }, 'isClosed': false } },
    { 'title': 'Армянский коньяк', 'section': { 'path': { 'path': '/cognac/armenia/', 'value': '/cognac/armenia/' }, 'isClosed': false } },
    { 'title': 'Хеннесси', 'section': { 'path': { 'path': '/cognac/hennessy/', 'value': '/cognac/hennessy/' }, 'isClosed': false } },
    { 'title': 'Courvoisier', 'section': { 'path': { 'path': '/cognac/courvoisier/', 'value': '/cognac/courvoisier/' }, 'isClosed': false } },
    { 'title': 'Чешское пиво', 'section': { 'path': { 'path': '/beer/czech/', 'value': '/beer/czech/' }, 'isClosed': false } },
    { 'title': 'Немецкое пиво', 'section': { 'path': { 'path': '/beer/germany/', 'value': '/beer/germany/' }, 'isClosed': false } },
    { 'title': 'Крафтовое пиво', 'section': { 'path': { 'path': '/beer/craft/', 'value': '/beer/craft/' }, 'isClosed': false } },
    { 'title': 'Шотландский виски', 'section': { 'path': { 'path': '/whisky/scotland/', 'value': '/whisky/scotland/' }, 'isClosed': false } },
    { 'title': 'Односолодовый виски', 'section': { 'path': { 'path': '/whisky/single-malt/', 'value': '/whisky/single-malt/' }, 'isClosed': false } },
    { 'title': 'Бурбон', 'section': { 'path': { 'path': '/whisky/bourbon/', 'value': '/whisky/bourbon/' }, 'isClosed': false } },
    { 'title': 'Виски Jack Daniels', 'section': { 'path': { 'path': '/whisky/jack-daniels/', 'value': '/whisky/jack-daniels/' }, 'isClosed': false } },
    { 'title': 'Виски Johnnie Walker', 'section': { 'path': { 'path': '/whisky/johnnie-walker/', 'value': '/whisky/johnnie-walker/' }, 'isClosed': false } },
    { 'title': 'Ром Bacardi', 'section': { 'path': { 'path': '/rum/bacardi/', 'value': '/rum/bacardi/' }, 'isClosed': false } },
    { 'title': 'Ликер Jagermeister', 'section': { 'path': { 'path': '/liqueur/jagermeister/', 'value': '/liqueur/jagermeister/' }, 'isClosed': false } }
  ]
}

export { getFooterInterestingSections }
