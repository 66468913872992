import { toRef } from 'vue'
import { useHydrationStore } from '#imports'

type SortingOptionInfo = {
  code: string
  nameMap: string[][]
}

export type SortingOption = [ string, SortingOptionInfo ]

type State = {
  counter: number
  shopsOpen: boolean
}

export function useMainStore () {
  const state = useHydrationStore<State>('main-store', {
    counter: 0,
    shopsOpen: false
  })

  function openModalCity () {
    const { openModal } = useModals()
    openModal('city-select')
  }

  return {
    openModalCity,

    shopsOpen: toRef(state.value, 'shopsOpen'),
    counter: toRef(state.value, 'counter')
  }
}
