import { reactive, toRef } from 'vue'
import { useRouter, useRoute } from '#app'

import type { RouteLocation } from 'vue-router'

const state = reactive({
  activeModal: null as any,
  modals: {} as any,
  activeSidebar: null as any,
  sidebars: {} as any
})

function defineModals (modals: any) {
  const route = useRoute()
  const modalHash = route.hash.replace('#', '')

  state.modals = { ...state.modals, ...modals }

  if (modalHash && state.modals[modalHash]) {
    openModal(modalHash)
  }
}

function defineSidebars (sidebars: any) {
  state.sidebars = { ...state.sidebars, ...sidebars }
}

async function openModal (name: string, props?: any) {
  const router = useRouter()
  const route = useRoute()

  if (state.modals[name]) {
    state.activeModal = [name, state.modals[name], props]

    await router.push({ path: route.path, hash: '#' + name, query: route.query })
  }
}

function openSidebar (name: string, props?: any) {
  if (state.sidebars[name]) {
    state.activeSidebar = [name, state.sidebars[name], props]
  }
}

async function closeModal (routeInfo?: Partial<RouteLocation>) {
  if (!state.activeModal) { return }

  const router = useRouter()
  const route = useRoute()
  const name = `#${state.activeModal[0]}`

  state.activeModal = null

  if (routeInfo) {
    // @ts-expect-error
    await router.replace(routeInfo)
    return
  }

  if (route.hash === name) {
    await router.back()
  }
}

async function closeSidebar () {
  state.activeSidebar = null
}

// function closeAllModals() {}

export function useModals () {
  return {
    defineModals,
    openModal,
    closeModal,
    // closeAllModals,
    defineSidebars,
    openSidebar,
    closeSidebar,

    activeModal: toRef(state, 'activeModal'),
    activeSidebar: toRef(state, 'activeSidebar')
  }
}
