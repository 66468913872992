import { useAPI, useCacheWrapper, useSharedPromise } from '#imports'

export function useSupportInfoStore () {
  const api = useAPI()

  async function getSupportInfo () {
    const { getSupportInfo } = api.marketing()

    return useSharedPromise('support-info', () => useCacheWrapper('support-info', async () => {
      const supportInfo = await getSupportInfo()

      return {
        ...supportInfo,
        supportPhoneHref: `tel:${supportInfo?.telephone?.replace(/(?!^)\D|\s/g, '')}`
      }
    }))
  }

  return {
    getSupportInfo
  }
}
